//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
//注册路由组件
Vue.use(VueRouter);
//编写路由规则
const routes = [
  {
    path: '/',
    redirect :'/administrators'
  },
  {
    path: '/administrators',
    name: 'Administrators',
    component: () => import('@/view/Administrators.vue')
  },
  {
    path: '/warning',
    name: 'warning',
    component: () => import('@/view/warning.vue')
  },
  {
    path: '/studentInfoData',
    name: 'studentInfoData',
    component: () => import('@/view/studentInfoData.vue')
  },
  {
    path: '/teacherInfoData',
    name: 'teacherInfoData',
    component: () => import('@/view/teacherInfoData.vue')
  },
  {
    path: '/teacherData',
    name: 'teacherData',
    component: () => import('@/view/teacherData.vue')
  },
  {
    path: '/gradePortrait',
    name: 'gradePortrait',
    component: () => import('@/view/gradePortrait.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/view/Login.vue')
  },
  {
    path: '/student-grades',
    name: 'StudentGrades',
    component: () => import('@/view/StudentGrades.vue')
  },
  {
    path: '/student-conduct',
    name: 'StudentConduct',
    component: () => import('@/view/StudentConduct.vue')
  },
  {
    path: '/student-conduct2',
    name: 'StudentConduct2',
    component: () => import('@/view/StudentConduct2.vue')
  },
  {
    path: '/student-conduct3',
    name: 'StudentConduct3',
    component: () => import('@/view/StudentConduct3.vue')
  },
  {
    path: '/portrait-class',
    name: 'PortraitClass',
    component: () => import('@/view/ClassPortrait.vue')
  },
  {
    path: '/student-healthy',
    name: 'StudentHealthy',
    component: () => import('@/view/StudentHealthy.vue')
  },
  {
    path: '/studentSafe',
    name: 'StudentSafe',
    component: () => import('@/view/StudentSafe.vue')
  }
]
//创建路由
const router = new VueRouter({
  mode: 'history',
  routes
})
//导出
export default router;