export default {
  install(Vue) {
    // 定义一个全局方法，可以在组件内部通过 this.$getFile 调用
    Vue.prototype.$getFile = function (name) {
      if (name) {
        // 返回一个 require 路径，这将由 webpack 处理
        return require('@/assets/images/datavImages/' + name);
      }
      return null;
    };
  }
};