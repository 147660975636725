<template>
  <div class="loading-warp" :class="customClass">
    <!-- <div class="loading" v-show="visible">{{ text }}</div> -->
    <dv-loading v-show="visible">{{ text }}</dv-loading>
  </div>
</template>

<script>
// import { dvLoading } from '@jiaminghi/data-view'

export default {
  // components: { dvLoading },
  data() {
    return {
      text: null,
      visible: false,
      body: false,
      lock: false,
      customClass: "",
    };
  },

  methods: {
    setText(text) {
      this.text = text;
    },
    close() {
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.classList.remove("loading-relative");
        this.$el.parentNode.removeChild(this.$el);
        this.$destroy();
      }
      this.visible = false;
    },
  },
};
</script>
<style lang="less">
.loading-relative {
  position: relative;
}
</style>
<style lang="less" scoped>
.loading-warp {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  //  background: rgba(#000, 0.6);
}
</style>