import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installFn from '@/common/utils'
import Loading from "@/components/loading/loading"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/reset.css'
import '@/assets/global.css'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import "@/less/theme.less";
import "@/less/theme1.less";
Vue.config.productionTip = false


import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
Vue.use(Loading)
Vue.use(installFn)
Vue.use(ElementUI)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
