import Vue from 'vue'
import loading from './loading.vue'

const defaults = {
 text: null,
 visible: false,
 customClass: ''
};
export default {
 install(vm, options = {}) {
  vm.prototype.$loadingDatav = (options = {}) => {
   const $app = Vue.extend(loading)
  //  console.log(options.target, options.domTarget, "options.target>>>")
   if (typeof options.target === 'string' && !options.domTarget) {
    options.target = document.querySelector(options.target);
    if (options.target) {
     options.target.classList.add('loading-relative');
    }
   }
   if (!options.target && options.domTarget) {
    options.target = options.domTarget;
    if (options.target) {
     options.target.classList.add('loading-relative');
    }
   }
   options.target = options.domTarget || options.target || document.body;
   let parent = options.body ? document.body : options.target;
   // options = Object.assign({}, defaults, options)
   let instance = new $app({
    el: document.createElement('div'),
    data: options
   });
   parent.appendChild(instance.$el);
   Vue.nextTick(() => {
    instance.visible = true;
   });
   return instance;
  }
 },
 Loading(options = {}) {
  if (typeof options.target === 'string') {
   options.target = document.querySelector(options.target);
  }
  options.target = options.target || document.body;
  let parent = options.body ? document.body : options.target;
  let instance = new $app({
   el: document.createElement('div'),
   data: options
  });
  parent.appendChild(instance.$el);
  Vue.nextTick(() => {
   instance.visible = true;
  });
  return instance;
 }
}